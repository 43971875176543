import Form from '@/components/form';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';
import { mutateGraphQL } from '../data';
import { CommercesWrite } from '../data/commerce/commerce.graphql';
import { PurchasesWrite } from '../data/commerce/purchase.graphql';
import StaffSelect from '../pages/formSelects/staffSelect';
import { MutationCommercesWriteArgs, MutationPurchasesWriteArgs } from '../types/schema';

export default function SelectStaffModal( {
	type,
	clearRows,
	selectedRows,
}: {
	clearRows,
	selectedRows: string[],
	type: string
} ) {
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	return (
		<Form
			initialValues={{ staff: null }}
			onSubmit={() => null}>
			{( formik ) => (
				<ResponsiveModalContainer
					title={`${t( 'common:staff-member' )} ${type}`}
					secondaryTitle={'Assign a different staff member to this ' + type}
					saveButtonProps={{ disabled: !formik.values.staff }}
					onClose={() => {
						closeModal();
					}}
					onSave={async () => {
						if ( !formik.values.staff ) {
							enqueueSnackbar( `${t( 'common:no-staff-member' )} ${type}s`, { variant: 'error' } );
							return;
						}
						
						if ( type === 'purchase' ) {
							await mutateGraphQL<MutationPurchasesWriteArgs>( {
								mutation : PurchasesWrite,
								variables: {
									inputs: selectedRows.map( ( id ) => ( {
										id   : validate( id ) ? id : undefined,
										staff: formik.values.staff?.id || null,
									} ) ),
								},
							} );
						} else {
							await mutateGraphQL<MutationCommercesWriteArgs>( {
								mutation : CommercesWrite,
								variables: {
									inputs: selectedRows.map( ( id ) => ( {
										id   : validate( id ) ? id : undefined,
										staff: formik.values.staff?.id || null,
									} ) ),
								},
							} );
						}
						clearRows();
						closeModal();
					}}>
					<StaffSelect
						name='staff'
						variables={{ options: { limit: 25 } }}
					/>
				</ResponsiveModalContainer>
			)}
		</Form>
	);
}
