import type { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import { InvoiceOrderEstimateEmailModal } from '@/modals/email/invoiceOrderEstimateEmailModal';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Order } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import {
	Edit as EditIcon,
	Email as EmailIcon,
	PictureAsPdf as PictureAsPdfIcon,
	Print as PrintIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { isEmpty } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useGetDeviceInfo from '../../../../../hooks/useGetDeviceInfo';
import { stayOpen } from '../../components/tableHelpers';

export function usePartialEstimateTableActions() {
	const { showModal } = useModal();
	const { isCloverDevice } = useGetDeviceInfo();
	const editable = usePermissions( permissions.estimates.write );
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const timezone = getBrowserTimezone();
	const theme = useTheme();
	
	return ( estimate: Order, closeModal?: () => void ) => {
		const dataColor = estimate.metadata?.documentLayout?.color || staff.company.metadata?.documentLayout?.color;
		const themeBasedLayoutColor = !isEmpty( dataColor?.light ) ? dataColor?.light?.slice( 1 ) : undefined;
		return [
			editable && {
				name   : t( 'common:edit' ),
				icon   : <EditIcon/>,
				onClick: () => closeModal?.(),
				props  : {
					component: PageLinkComponent,
					href     : `/dashboard/commerce/estimates/${estimate.id}/edit`,
				},
			}, {
				name   : isEmpty( estimate.metadata?.sentDates ) ? t( 'common:email' ) : t( 'common:remail' ),
				icon   : <EmailIcon/>,
				onClick: async () => showModal( InvoiceOrderEstimateEmailModal, {
					onClose: ( event, reason ) => stayOpen( event, reason ),
				}, { id: estimate.id } ),
			}, {
				name : t( 'common:print' ),
				icon : <PrintIcon/>,
				props: {
					component: PageLinkComponent,
					href     : `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/print/${estimate.id}/invoice?timezone=${timezone}${themeBasedLayoutColor
						? `&themeBasedLayoutColor=${themeBasedLayoutColor}`
						: ''}&themeMode=${theme.palette.mode}`,
					target   : '_blank',
				},
			}, !isCloverDevice && {
				name : t( 'common:pdf' ),
				icon : <PictureAsPdfIcon/>,
				props: {
					component: PageLinkComponent,
					href     : `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${estimate.id}/estimate?timezone=${timezone}${themeBasedLayoutColor
						? `&themeBasedLayoutColor=${themeBasedLayoutColor}`
						: ''}&themeMode=${theme.palette.mode}`,
					target   : '_blank',
				},
			}, !isCloverDevice && {
				name : t( 'common:pdf-no-price' ),
				icon : <PictureAsPdfIcon/>,
				props: {
					component: PageLinkComponent,
					href     : `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${estimate.id}/estimate?s=pickup&timezone=${timezone}${themeBasedLayoutColor
						? `&themeBasedLayoutColor=${themeBasedLayoutColor}`
						: ''}&themeMode=${theme.palette.mode}`,
					target   : '_blank',
				},
			},
		] as ActionProps[];
	};
}
